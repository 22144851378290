import { estimateGas as viem_estimateGas, sendTransaction as viem_sendTransaction } from 'viem/actions';
import { getAction } from '../utils/getAction.js';
import { getAccount } from './getAccount.js';
import { getConnectorClient } from './getConnectorClient.js';
/** https://wagmi.sh/core/api/actions/sendTransaction */
export async function sendTransaction(config, parameters) {
  const {
    account,
    chainId,
    connector,
    gas: gas_,
    ...rest
  } = parameters;
  let client;
  if (typeof account === 'object' && account?.type === 'local') client = config.getClient({
    chainId
  });else client = await getConnectorClient(config, {
    account: account ?? undefined,
    chainId,
    connector
  });
  const {
    connector: activeConnector
  } = getAccount(config);
  const gas = await (async () => {
    // Skip gas estimation if `data` doesn't exist (not a contract interaction).
    if (!('data' in parameters) || !parameters.data) return undefined;
    // Skip gas estimation if connector supports simulation.
    if ((connector ?? activeConnector)?.supportsSimulation) return undefined;
    // Skip gas estimation if `null` is provided.
    if (gas_ === null) return undefined;
    // Run gas estimation if no value is provided.
    if (gas_ === undefined) {
      const action = getAction(client, viem_estimateGas, 'estimateGas');
      return action({
        ...rest,
        account,
        chain: chainId ? {
          id: chainId
        } : null
      });
    }
    // Use provided gas value.
    return gas_;
  })();
  const action = getAction(client, viem_sendTransaction, 'sendTransaction');
  const hash = await action({
    ...rest,
    ...(account ? {
      account
    } : {}),
    gas,
    chain: chainId ? {
      id: chainId
    } : null
  });
  return hash;
}
