import { getVersion } from './internal/errors.js';
/**
 * Base error class inherited by all errors thrown by ox.
 *
 * @example
 * ```ts
 * import { Errors } from 'ox'
 * throw new Errors.BaseError('An error occurred')
 * ```
 */
export class BaseError extends Error {
  constructor(shortMessage, options = {}) {
    const details = (() => {
      if (options.cause instanceof BaseError) {
        if (options.cause.details) return options.cause.details;
        if (options.cause.shortMessage) return options.cause.shortMessage;
      }
      if (options.cause?.message) return options.cause.message;
      return options.details;
    })();
    const docsPath = (() => {
      if (options.cause instanceof BaseError) return options.cause.docsPath || options.docsPath;
      return options.docsPath;
    })();
    const docsBaseUrl = 'https://oxlib.sh';
    const docs = `${docsBaseUrl}${docsPath ?? ''}`;
    const message = [shortMessage || 'An error occurred.', ...(options.metaMessages ? ['', ...options.metaMessages] : []), ...(details || docsPath ? ['', details ? `Details: ${details}` : undefined, docsPath ? `See: ${docs}` : undefined] : [])].filter(x => typeof x === 'string').join('\n');
    super(message, options.cause ? {
      cause: options.cause
    } : undefined);
    Object.defineProperty(this, "details", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: void 0
    });
    Object.defineProperty(this, "docs", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: void 0
    });
    Object.defineProperty(this, "docsPath", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: void 0
    });
    Object.defineProperty(this, "shortMessage", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: void 0
    });
    Object.defineProperty(this, "cause", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: void 0
    });
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'BaseError'
    });
    Object.defineProperty(this, "version", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: `ox@${getVersion()}`
    });
    this.cause = options.cause;
    this.details = details;
    this.docs = docs;
    this.docsPath = docsPath;
    this.shortMessage = shortMessage;
  }
  walk(fn) {
    return walk(this, fn);
  }
}
/** @internal */
function walk(err, fn) {
  if (fn?.(err)) return err;
  if (err && typeof err === 'object' && 'cause' in err && err.cause) return walk(err.cause, fn);
  return fn ? null : err;
}
