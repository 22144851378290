import { BlockNotFoundError } from '../../errors/block.js';
import { numberToHex } from '../../utils/encoding/toHex.js';
import { formatBlock } from '../../utils/formatters/block.js';
/**
 * Returns information about a block at a block number, hash, or tag.
 *
 * - Docs: https://viem.sh/docs/actions/public/getBlock
 * - Examples: https://stackblitz.com/github/wevm/viem/tree/main/examples/blocks_fetching-blocks
 * - JSON-RPC Methods:
 *   - Calls [`eth_getBlockByNumber`](https://ethereum.org/en/developers/docs/apis/json-rpc/#eth_getblockbynumber) for `blockNumber` & `blockTag`.
 *   - Calls [`eth_getBlockByHash`](https://ethereum.org/en/developers/docs/apis/json-rpc/#eth_getblockbyhash) for `blockHash`.
 *
 * @param client - Client to use
 * @param parameters - {@link GetBlockParameters}
 * @returns Information about the block. {@link GetBlockReturnType}
 *
 * @example
 * import { createPublicClient, http } from 'viem'
 * import { mainnet } from 'viem/chains'
 * import { getBlock } from 'viem/public'
 *
 * const client = createPublicClient({
 *   chain: mainnet,
 *   transport: http(),
 * })
 * const block = await getBlock(client)
 */
export async function getBlock(client, {
  blockHash,
  blockNumber,
  blockTag: blockTag_,
  includeTransactions: includeTransactions_
} = {}) {
  const blockTag = blockTag_ ?? 'latest';
  const includeTransactions = includeTransactions_ ?? false;
  const blockNumberHex = blockNumber !== undefined ? numberToHex(blockNumber) : undefined;
  let block = null;
  if (blockHash) {
    block = await client.request({
      method: 'eth_getBlockByHash',
      params: [blockHash, includeTransactions]
    }, {
      dedupe: true
    });
  } else {
    block = await client.request({
      method: 'eth_getBlockByNumber',
      params: [blockNumberHex || blockTag, includeTransactions]
    }, {
      dedupe: Boolean(blockNumberHex)
    });
  }
  if (!block) throw new BlockNotFoundError({
    blockHash,
    blockNumber
  });
  const format = client.chain?.formatters?.block?.format || formatBlock;
  return format(block);
}
