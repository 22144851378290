import { createAction, props } from '@ngrx/store';

export const setMinWithdrawValue = createAction(
  '[Settings] set minimal withdraw value',
  props<{ minWithdrawValue: number }>(),
);

export const setMaxWithdrawValue = createAction(
  '[Settings] set maximum withdraw value',
  props<{ maxWithdrawValue: number }>(),
);

export const setAppLocale = createAction(
  '[Settings] set locale value',
  props<{ locale: string }>(),
);
