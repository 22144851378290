import { ens_normalize } from '@adraffy/ens-normalize';
import * as Bytes from './Bytes.js';
import * as Hash from './Hash.js';
import * as Hex from './Hex.js';
import * as internal from './internal/ens.js';
/**
 * Hashes ENS label.
 *
 * Since ENS labels prohibit certain forbidden characters (e.g. underscore) and have other validation rules, you likely want to [normalize ENS labels](https://docs.ens.domains/contract-api-reference/name-processing#normalising-names) with [UTS-46 normalization](https://unicode.org/reports/tr46) before passing them to `labelhash`. You can use the built-in {@link ox#Ens.(normalize:function)} function for this.
 *
 * @example
 * ```ts twoslash
 * import { Ens } from 'ox'
 * Ens.labelhash('eth')
 * '0x4f5b812789fc606be1b3b16908db13fc7a9adf7ca72641f84d75b47069d3d7f0'
 * ```
 *
 * @param label - ENS label.
 * @returns ENS labelhash.
 */
export function labelhash(label) {
  const result = new Uint8Array(32).fill(0);
  if (!label) return Hex.fromBytes(result);
  return internal.unwrapLabelhash(label) || Hash.keccak256(Hex.fromString(label));
}
labelhash.parseError = error => /* v8 ignore next */
error;
/**
 * Hashes ENS name.
 *
 * Since ENS names prohibit certain forbidden characters (e.g. underscore) and have other validation rules, you likely want to [normalize ENS names](https://docs.ens.domains/contract-api-reference/name-processing#normalising-names) with [UTS-46 normalization](https://unicode.org/reports/tr46) before passing them to `namehash`. You can use the built-in {@link ox#Ens.(normalize:function)} function for this.
 *
 * @example
 * ```ts twoslash
 * import { Ens } from 'ox'
 * Ens.namehash('wevm.eth')
 * // @log: '0xf246651c1b9a6b141d19c2604e9a58f567973833990f830d882534a747801359'
 * ```
 *
 * @param name - ENS name.
 * @returns ENS namehash.
 */
export function namehash(name) {
  let result = new Uint8Array(32).fill(0);
  if (!name) return Hex.fromBytes(result);
  const labels = name.split('.');
  // Iterate in reverse order building up hash
  for (let i = labels.length - 1; i >= 0; i -= 1) {
    const hashFromEncodedLabel = internal.unwrapLabelhash(labels[i]);
    const hashed = hashFromEncodedLabel ? Bytes.fromHex(hashFromEncodedLabel) : Hash.keccak256(Bytes.fromString(labels[i]), {
      as: 'Bytes'
    });
    result = Hash.keccak256(Bytes.concat(result, hashed), {
      as: 'Bytes'
    });
  }
  return Hex.fromBytes(result);
}
namehash.parseError = error => /* v8 ignore next */
error;
/**
 * Normalizes ENS name according to [ENSIP-15](https://github.com/ensdomains/docs/blob/9edf9443de4333a0ea7ec658a870672d5d180d53/ens-improvement-proposals/ensip-15-normalization-standard.md).
 *
 * For more info see [ENS documentation](https://docs.ens.domains/contract-api-reference/name-processing#normalising-names) on name processing.
 *
 * @example
 * ```ts twoslash
 * import { Ens } from 'ox'
 * Ens.normalize('wevm.eth')
 * // @log: 'wevm.eth'
 * ```
 *
 * @param name - ENS name.
 * @returns Normalized ENS name.
 */
export function normalize(name) {
  return ens_normalize(name);
}
normalize.parseError = error => /* v8 ignore next */
error;
