var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ChainController, ModalController, RouterController } from '@reown/appkit-core';
import { customElement } from '@reown/appkit-ui';
import { LitElement, html } from 'lit';
import styles from './styles.js';
import { ConstantsUtil } from '@reown/appkit-common';
import { property } from 'lit/decorators.js';
let W3mSwitchActiveChainView = class W3mSwitchActiveChainView extends LitElement {
  constructor() {
    super(...arguments);
    this.unsubscribe = [];
    this.switchToChain = RouterController.state.data?.switchToChain;
    this.navigateTo = RouterController.state.data?.navigateTo;
    this.navigateWithReplace = RouterController.state.data?.navigateWithReplace;
    this.caipNetwork = RouterController.state.data?.network;
    this.activeChain = ChainController.state.activeChain;
  }
  firstUpdated() {
    this.unsubscribe.push(ChainController.subscribeKey('activeChain', val => this.activeChain = val));
  }
  disconnectedCallback() {
    this.unsubscribe.forEach(unsubscribe => unsubscribe());
  }
  render() {
    const switchedChainNameString = this.switchToChain ? ConstantsUtil.CHAIN_NAME_MAP[this.switchToChain] : 'supported';
    if (!this.switchToChain) {
      return null;
    }
    const nextChainName = this.switchToChain === 'eip155' ? 'Ethereum' : this.switchToChain;
    return html`
      <wui-flex
        flexDirection="column"
        alignItems="center"
        .padding=${['3xl', 'xl', 'xl', 'xl']}
        gap="xl"
      >
        <wui-flex justifyContent="center" flexDirection="column" alignItems="center" gap="xl">
          <wui-visual
            name=${this.switchToChain === 'eip155' ? 'eth' : this.switchToChain}
          ></wui-visual>
          <wui-text
            data-testid=${`w3m-switch-active-chain-to-${nextChainName}`}
            variant="paragraph-500"
            color="fg-100"
            align="center"
            >Switch to <span class="capitalize">${nextChainName}</span></wui-text
          >
          <wui-text variant="small-400" color="fg-200" align="center">
            Connected wallet doesn't support connecting to ${switchedChainNameString} chain. You
            need to connect with a different wallet.
          </wui-text>
          <wui-button size="md" @click=${this.switchActiveChain.bind(this)}>Switch</wui-button>
        </wui-flex>
      </wui-flex>
    `;
  }
  async switchActiveChain() {
    if (!this.switchToChain) {
      return;
    }
    if (this.caipNetwork) {
      await ChainController.switchActiveNetwork(this.caipNetwork);
    } else {
      ChainController.setActiveNamespace(this.switchToChain);
    }
    ModalController.close();
    ModalController.open({
      view: 'Connect'
    });
  }
};
W3mSwitchActiveChainView.styles = styles;
__decorate([property()], W3mSwitchActiveChainView.prototype, "activeChain", void 0);
W3mSwitchActiveChainView = __decorate([customElement('w3m-switch-active-chain-view')], W3mSwitchActiveChainView);
export { W3mSwitchActiveChainView };
